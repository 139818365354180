import React, { useEffect, useState } from "react";
import { useGlobal } from "reactn";
import { isEmpty, isObject, jsonParse } from "../lib/util";
import { getFeatsOptions } from "../lib/oss-schema";
import { loadImages, loadPropertyData } from "../lib/oss-api";
import { getPropertyInfo } from "../lib/local-data";
import { isAdminRole } from "../lib/user-roles";

import getLogger from "../lib/debug-logger";
import { loadFeatSources } from "../lib/oss-ms-api";

const log = getLogger("withPropertyFeatures", 2);

// strip some large or empty data for logging only
log("stored property data", getPropertyInfo()); //--------log

const withPropertyFeatures = (WrappedComp) => {
  return (props) => {
    const [pageName] = useGlobal("PAGE_NAME");

    const [userRole] = useGlobal("USER_ROLE");

    // property street address as string
    const [addrLine] = useGlobal("ADDRESS_LINE");

    // property street address as string
    const [addrLegalLine] = useGlobal("ADDRESS_LEGAL_LINE");

    // address object for search by legal info
    const [legalSearchObj] = useGlobal("ADDRESS_REQ_OBJECT");

    // advanced search used?
    const [searchByLegal] = useGlobal("SEARCH_BY_LEGAL");

    const [dataSchema] = useGlobal("DATA_SCHEMA");

    // construction feats sources (admin only)
    const [featSources] = useGlobal("FEATURES_SOURCES");

    // initial and updated construction fearures snapshots
    const [initFeatsSnapshot] = useGlobal("FEATURES_INITIAL");
    const [lastFeatsSnapshot] = useGlobal("FEATURES_CURRENT");

    // initial and updated valuation data snapshots
    const [initValSnapshot] = useGlobal("VALUATION_INITIAL");
    const [lastValSnapshot] = useGlobal("VALUATION_SNAPSHOT");

    // property stats - initial, updated, and current/unsaved JSON objects
    const [initStatsSnapshot] = useGlobal("STATS_INITIAL");
    const [updatedStatsSnapshot] = useGlobal("STATS_UPDATED");
    const [currentStatsJson] = useGlobal("STATS_CURRENT");

    // list of construction features updated since last save
    const [dirtyInputs] = useGlobal("DIRTY_KEYS");

    // list of construction features currently having invalid values
    const [invalidInputs] = useGlobal("INVALID_INPUTS");

    // current property type
    const [propertyType] = useGlobal("PROPERTY_TYPE");

    // initial property data call is complete - for both success or failure
    // values: 'net' or 'cache'
    const [initDataSource] = useGlobal("PROPERTY_SOURCE");

    // property construction features / data error messages, if any
    const [dataError, setDataError] = useGlobal("ERROR");
    const [prefillError, setPrefillError] = useGlobal("PREFILL_ERROR");

    // construction features loading timestamp (is set on both success and error)
    const [initCallTime] = useGlobal("PROPERTY_TIME");

    // AVM recalculation is enabled
    const [recalcEnabled, setRecalcEnabled] = useGlobal("RECALC_ENABLED");

    // Save button recalculation is enabled
    const [saveEnabled, setSaveEnabled] = useGlobal("SAVE_ENABLED");

    // Construction features' reset is enabled
    const [resetEnabled, setResetEnabled] = useGlobal("RESET_ENABLED");

    // 'Overlord' button is enabled
    const [overlordEnabled, setOverlordEnabled] = useGlobal("OVERLORD_ENABLED");

    // 'Overlord' / 'Submit for review' buttons are enabled
    const [reviewEnabled, setReviewEnabled] = useGlobal("REVIEW_ENABLED");

    // AVM has already been requested
    const [valueRequested] = useGlobal("AVM_REQUESTED");

    // Range has already been requested
    const [rangeRequested] = useGlobal("VALUE_RANGE_REQUESTED");

    // this object contains data points descriptions from OSS schema
    const [featuresSchema, setFeaturesSchema] = useState(null);

    // updated and saved construction features that affect AVM calculation
    const [currentFeatures, setCurrentFeatures] = useState(null);

    // construction features updated but not saved
    const [featuresNotSaved, setFeaturesNotSaved] = useState(false);

    // construction features have been updated and saved
    const [featsChanged, setFeatsChanged] = useState(false);

    // property stats have been updated but not saved yet
    const [statsDirty, setStatsDirty] = useState(false);

    // property stats have been updated
    const [, setStatsUpdated] = useState(false);

    // AVM values have been updated
    const [avmChanged, setAvmChanged] = useState(false);

    const [requestSent, setRequestSent] = useState(false);

    const [requestAddress, setRequestAddress] = useState(null);

    //---------------------------------------------------------------
    // determine current property address
    //---------------------------------------------------------------
    useEffect(() => {
      const addr = addrLine || legalSearchObj || null;
      setRequestAddress(addr);
    }, [addrLine, legalSearchObj, setRequestAddress]);

    //---------------------------------------------------------------
    // load initial OSS products if not loaded yet
    //---------------------------------------------------------------
    useEffect(() => {
      //const requestAddr = addrLine || legalSearchObj; // string or object
      if (requestSent || initFeatsSnapshot || !requestAddress) return;
      setRequestSent(true);
      log(`loading property data prefill for address`, requestAddress); //----------------- log
      loadPropertyData(requestAddress);
    }, [requestAddress, initFeatsSnapshot, requestSent, setRequestSent]);

    //---------------------------------------------------------------
    // drop prefill/data errors when prefill is available
    //---------------------------------------------------------------
    useEffect(() => {
      if (!initFeatsSnapshot) return;
      if (dataError) setDataError(null);
      if (prefillError) setPrefillError(null);
    }, [initFeatsSnapshot, dataError, prefillError, setDataError, setPrefillError]);

    //---------------------------------------------------------------
    // load constr. feats sources, if the user is Admin
    //---------------------------------------------------------------
    useEffect(()=>{
      if (!propertyType || !isAdminRole(userRole) || isObject(featSources)) return;
      log(`loading construction features' sources`); //------------------------------------ log
      loadFeatSources();
    }, [propertyType, userRole, featSources])

    //---------------------------------------------------------------
    // checking if features have been updated/saved
    //---------------------------------------------------------------
    useEffect(() => {
      const flag = !!initFeatsSnapshot && initFeatsSnapshot !== lastFeatsSnapshot;
      flag && log(`construction features updated`); //--------- log
      setFeatsChanged(flag);
    }, [initFeatsSnapshot, lastFeatsSnapshot, setFeatsChanged]);

    //---------------------------------------------------------------
    // checking if AVM values have changed
    //---------------------------------------------------------------
    useEffect(() => {
      const flag = !!initValSnapshot && !!lastValSnapshot && initValSnapshot !== lastValSnapshot;
      flag && log(`valuation data updated`, {initValSnapshot, lastValSnapshot}); //--------- log
      setAvmChanged(flag);
    }, [initValSnapshot, lastValSnapshot, setAvmChanged]);

    //---------------------------------------------------------------
    // checking if property stats changes not saved
    //---------------------------------------------------------------
    useEffect(() => {
      const refJson = updatedStatsSnapshot || initStatsSnapshot;
      const flag = refJson && currentStatsJson && currentStatsJson !== refJson;
      flag && log(`property stats have changed`); //--------- log
      setStatsDirty(flag);
    }, [initStatsSnapshot, updatedStatsSnapshot, currentStatsJson, setStatsDirty]);

    //---------------------------------------------------------------
    // checking if property stats have been updated
    //---------------------------------------------------------------
    useEffect(() => {
      const flag =
        initStatsSnapshot && updatedStatsSnapshot && initStatsSnapshot !== updatedStatsSnapshot;
      flag && log(`property stats updated`); //--------- log
      setStatsUpdated(flag);
    }, [initStatsSnapshot, updatedStatsSnapshot, setStatsUpdated]);

    //---------------------------------------------------------------
    // getting construction features' options to build the form
    //---------------------------------------------------------------
    useEffect(() => {
      if (!propertyType || !dataSchema) return;
      const obj = getFeatsOptions(dataSchema, propertyType);
      //log(`features schema`, obj); //----------------------------------- log
      setFeaturesSchema(obj);
    }, [propertyType, dataSchema, setFeaturesSchema]);

    //---------------------------------------------------------------
    // refresh imagery if data comes from cache
    //---------------------------------------------------------------
    useEffect(() => {
      if (initDataSource === "cache") {
        log(`refreshing property imagery`); //----------------------------------- log
        loadImages();
      }
    }, [pageName, initDataSource]);

    //---------------------------------------------------------------
    // checking if edited features aren't saved
    //---------------------------------------------------------------
    useEffect(() => {
      const unsaved = !isEmpty(dirtyInputs);
      setFeaturesNotSaved(unsaved);
    }, [dirtyInputs, setFeaturesNotSaved]);

    //---------------------------------------------------------------
    // 'Save' button control
    //---------------------------------------------------------------
    useEffect(() => {
      const flag = (featuresNotSaved || statsDirty) && isEmpty(invalidInputs);
      setSaveEnabled(flag);
    }, [featuresNotSaved, statsDirty, invalidInputs, setSaveEnabled]);

    //---------------------------------------------------------------
    // saving updated construction features in state var
    //---------------------------------------------------------------
    useEffect(() => {
      if (!lastFeatsSnapshot) return;
      let feats = jsonParse(lastFeatsSnapshot);
      log(`saving updated construction features`, feats); //----------------------- log
      setCurrentFeatures(feats);
    }, [lastFeatsSnapshot, setCurrentFeatures]);

    //---------------------------------------------------------------
    // Recalculation button control
    //---------------------------------------------------------------
    useEffect(() => {
      const flag = saveEnabled && (valueRequested || rangeRequested);
      //log(`Recalculation is ${flag ? "enabled" : "disabled"}`); //----------- log
      setRecalcEnabled(flag);
    }, [saveEnabled, valueRequested, rangeRequested, setRecalcEnabled]);

    //---------------------------------------------------------------
    // Reset, Review and Overlord buttons control
    //---------------------------------------------------------------
    useEffect(() => {
      const flag = featsChanged || avmChanged || statsDirty || !isEmpty(dirtyInputs);
      log(`Reset is ${flag ? "enabled" : "disabled"}`, { featsChanged, avmChanged, dirtyInputs }); //----------- log
      setResetEnabled(flag);
    }, [featsChanged, avmChanged, dirtyInputs, statsDirty, setResetEnabled]);

    useEffect(() => {
      const flag = (featsChanged || avmChanged) && isEmpty(dirtyInputs);
      setOverlordEnabled(flag);
    }, [featsChanged, avmChanged, dirtyInputs, setOverlordEnabled]);

    useEffect(() => {
      setReviewEnabled(!!lastFeatsSnapshot);
    }, [lastFeatsSnapshot, setReviewEnabled]);

    if (pageName !== "main") {
      return (
        <div className="error-text">
          {"GUI error: Property data wrapper applicable only to main page"}
        </div>
      );
    }

    return (
      <WrappedComp
        searchByLegal={!!searchByLegal}
        propertyAddress={addrLine || addrLegalLine}
        currentFeatures={currentFeatures}
        saveEnabled={saveEnabled}
        recalcEnabled={recalcEnabled}
        resetEnabled={resetEnabled}
        reviewEnabled={reviewEnabled}
        overlordEnabled={overlordEnabled}
        initCallTime={initCallTime}
        featuresPresent={!!currentFeatures}
        prefillError={prefillError}
        featuresSchema={featuresSchema}
        featuresSources={featSources}
        {...props}
      />
    );
  };
};

export default withPropertyFeatures;
