import config from "../config";
import {
  arrayUnique,
  digObject,
  getProp,
  isArray,
  isFunction,
  isObject,
  isPostal,
  isString,
  jsonParse,
  listSize,
  splitWords,
} from "./util";
import { dropSession } from "./session";
import { buildRequestHeader, buildPropertyPostData } from "./oss-util";
import { getAddresses, transformPropertyData } from "./oss-transform";
import { getHttpClient } from "./api";
import { transformUpdatedFeatures } from "./features-config";
import {
  getAuthToken,
  userHasProduct,
  getPropertyType,
  setError,
  filterAvailableProducts,
  getGlobal,
  setGlobal,
  getGlobalValue,
  resetApiGlobals,
  setGlobalValue,
} from "./global-util";
import { setAndStoreGlobalValue, setAndStoreGlobals, storePropertyInfo } from "./local-data";
import getLogger from "./debug-logger";

const log = getLogger("oss-api", 1);

const { ossApiEndpoint, ossApiTimeout } = config;

//------------------------------------------------------------
// send OSS POST request
//------------------------------------------------------------
export const ossPost = async (postData) => {
  let error = "",
    message = "";
  const conf = {
    method: "POST",
    url: ossApiEndpoint,
    timeout: ossApiTimeout,
    validateStatus: null, // resolve error responses
    data: postData,
  };
  //
  const token = getAuthToken();
  if (!token) {
    log("Access token not available"); //------------------- log
    return null;
  }
  conf.headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };
  const client = getHttpClient();
  log(`Sending POST request to ${conf.url}`, "REQUEST DATA >>>", conf.data, "<<< END"); //------ log
  //
  try {
    const res = await client.request(conf);
    if (!res || !res.data) {
      log(`No data returned from OSS API call`); //---------------------- log
    }
    const status = getProp(res, "status", 0);
    const statusText = getProp(res, "statusText", "error");
    error = digObject(res, "data", "error") || "";
    if (status === 401 || error === "invalid_token") {
      log(`${status} ${statusText}`);
      message = "Access denied";
      setError(message);
      dropSession();
      return res;
    }
    log("OSS response received", "RESPONSE DATA >>>", res.data, "<<< END"); //-------- log
    return res.data;
  } catch (x) {
    const msg = (typeof x === "object" && x.message) || x + "";
    log(msg);
  }
  return null;
};

// Address search
const addressSearch = async (searchTerm) => {
  const product = "AddressSearch";
  if (!userHasProduct(product)) {
    log(`${product} is not available for this user`); //---------- log
  }
  const addrObj = isPostal(searchTerm) ? { PostalCode: searchTerm } : { StreetAddress: searchTerm };
  const data = {
    RequestHeader: buildRequestHeader(),
    RequestBody: {
      Property: [
        {
          Address: addrObj,
        },
      ],
      RequestedProducts: {
        Product: [product],
      },
    },
  };
  const respData = await ossPost(data);
  return respData;
};

// extract addresses from response data
export const fetchAddresses = async (searchTerm) => {
  const respData = await addressSearch(searchTerm);
  return getAddresses(respData); // array or null
};

//----------------------------------------------------------
// getting OSS products (set propertyAddress to null to use current global address)
//----------------------------------------------------------
export const fetchProducts = async (propertyAddress, prodList, payload = null) => {
  const address =
    propertyAddress || getGlobalValue("ADDRESS_LINE") || getGlobalValue("ADDRESS_REQ_OBJECT");

  if (!listSize(prodList)) {
    log(`none of requested products available for current user`); //----- log
    return null;
  }
  const postData = buildPropertyPostData(address, prodList, payload);
  if (!postData) {
    log(`could not create POST data for address`, address); //-------------- log
    return null;
  }

  const respData = await ossPost(postData);
  if (!isObject(respData)) {
    log(`response data is ${respData}`); //-------------- log
    return null;
  }
  return respData;
};

//----------------------------------------------------------
// load initial property data from cache or API
// actual requested product list depends on user configuration
//----------------------------------------------------------
export const loadPropertyData = async (searchAddr) => {
  resetApiGlobals();

  const revealRange = !!getGlobalValue("REVEAL_EVR");
  const revealAvm = !!getGlobalValue("REVEAL_AVM");

  const prodList = [
    "iClarifyFinancialResidentialPrefill",
    "BuildingImagery",
    "iClarifyFinancialResidentialReport",
    "PropertyListings",
    "PropertySales",
    //
    "AssessmentListings",
    "RentalListings",
    //
    "PropertyStats",
    "NeighbourhoodName",
    "MarketMetrics",
    "HPIHistorical",
    "OptaPermitFinancial",
    revealRange && "EstimatedValueRange",
    revealAvm && "ResidentialMarketValuation",
    revealAvm && "MarketValuationConfidence",
  ].filter(Boolean);

  const callList = filterAvailableProducts(prodList, false); // only available OSS products

  if (revealAvm) setAndStoreGlobalValue("AVM_REQUESTED", true);
  if (revealRange) setAndStoreGlobalValue("VALUE_RANGE_REQUESTED", true);

  log(`initial property data call`); //------------------------ log
  const respData = await fetchProducts(searchAddr, callList);
  const transformed = transformPropertyData(respData, prodList); // always returns object

  setGlobal(transformed);

  // check if prefill data exists, store retrieved data locally
  if (getProp(transformed, "FEATURES_CURRENT")) {
    storePropertyInfo();

    if (!getProp(transformed, "REPORT_URL")) updateReportUrl(1);
  }
 
  return transformed;
};

//----------------------------------------------------------
// Updating valuation products, keeping PDF report updated
// with all previous changes.
// This acts as "Save" operation when no products explicitly specified
//----------------------------------------------------------
const updatePropertyProducts = async (products) => {
  // reset error messages
  resetApiGlobals();
  // check if products is available
  let prodList = isString(products) ? splitWords(products) : isArray(products) ? [...products] : [];

  const globs = getGlobal();
  const hasReport = prodList.includes("iClarifyFinancialResidentialReport");
  const hasSnapshot = prodList.includes("iClarifySnapshotReport");
  const rentalType = globs.RENTAL_TYPE;
  const hasRental = !!rentalType && globs.RENTAL_REQUESTED;
  const hasValuation = !!globs.AVM_REQUESTED;
  const hasRange = !!globs.VALUE_RANGE_REQUESTED;

  // explicitly add recalculation of market value/range if those were requested before
  if (hasValuation) prodList.push("ResidentialMarketValuation");
  if (hasRange) prodList.push("EstimatedValueRange");

  // drop report if the product is requested
  // drop existing report, if new requested
  hasReport && setGlobalValue("REPORT_URL", null);

  // if Report is requested include all products that have already been pulled in:
  // those additional products won't be included in data transformation
  let actualProdList = [
    ...prodList,
    hasReport && hasRental && "RentalValuation",
    hasReport && hasValuation && "ResidentialMarketValuation",
    hasSnapshot && "EstimatedValueRange",
    hasReport && (hasRental || hasValuation) && "MarketValuationConfidence",
    hasReport && "PropertyListings",
    hasReport && "PropertySales",
    hasReport && "AssessmentListings",
    hasReport && "RentalListings",
    hasReport && "PropertyStats",
    hasReport && "OptaPermitFinancial",
    (hasReport || hasSnapshot) && "NeighbourhoodName",
    (hasReport || hasSnapshot) && "MarketMetrics",
    hasReport && "HPIHistorical",
  ].filter(Boolean);

  prodList = arrayUnique(prodList); // remove duplicates

  actualProdList = filterAvailableProducts(actualProdList);

  if (!actualProdList) {
    log(`transformed product list is empty`); //------------------------ log
    return null;
  }
  // in case Report is not configured for the used
  if (hasReport && !actualProdList.includes("iClarifyFinancialResidentialReport")) {
    log(`PDF Report is not allowed for this user, check configutation!`); //------------- log
    return null;
  }

  // build request payload
  const refObj = jsonParse(globs.FEATURES_INITIAL) || {}; // original data object
  // separate construction feats from stats, if added

  const propType = getPropertyType(); // current property type

  // list of missing data entries, each one populated with empty string ''
  const missingDataKeys = splitWords(globs.FEATURES_MISSING);

  // collect updated data points
  let payload = transformUpdatedFeatures(globs, refObj, propType, missingDataKeys);

  log(`features in payload`, payload); //------------------ log

  if (actualProdList.includes("RentalValuation")) {
    payload = {
      ...(payload || {}),
      RentalInformation: {
        RentalUnitType: rentalType,
      },
    };
  }
  // add updated stats on Report calls, if present
  if (hasReport) {
    const updStatsJson = getGlobalValue("STATS_CURRENT") || getGlobalValue("STATS_UPDATED");
    if (updStatsJson) {
      payload = {
        ...(payload || {}),
        PropertyStats: [jsonParse(updStatsJson)],
      };
    }
  }

  const [addrLine, addrObj] = [globs.ADDRESS_LINE, globs.ADDRESS_REQ_OBJECT];
  const addr = addrLine || addrObj;

  log(`fetching product updates`); //----------------- log
  const respData = await fetchProducts(addr, actualProdList, payload);
  const transformed = isObject(respData)
    ? transformPropertyData(respData, prodList)
    : { ERROR: "No data" }; // transformed property data

  // if Report was requested but not returned, set it to ''
  if (hasReport && !getProp(transformed, "REPORT_URL")) {
    transformed.REPORT_URL = "";
  }
  setAndStoreGlobals(transformed);
  log("updated and saved global entries:", Object.keys(transformed).sort()); //------------ log
  return transformed;
};

//----------------------------------------------------------
// Update PDF Report URL
//----------------------------------------------------------
export const updateReportUrl = async (counter = 0) => {
  if (++counter > 3) return;
  log(`updating PDF Report URL, take ${counter}`); //--------------------------- log
  const data = await updatePropertyProducts(["iClarifyFinancialResidentialReport"]);
  if (!getProp(data, "REPORT_URL")) {
    updateReportUrl(counter);
  }
};

//----------------------------------------------------------
// load initial Market Value
//----------------------------------------------------------
export const loadMarketValue = async () => {
  setAndStoreGlobalValue("AVM_REQUESTED", true);
  const data = await updatePropertyProducts([
    "ResidentialMarketValuation",
    "MarketValuationConfidence",
    "iClarifyFinancialResidentialReport",
  ]);
  return data;
};

//----------------------------------------------------------
// load initial Estimated Value Range
//----------------------------------------------------------
export const loadEstimatedValueRange = async () => {
  setAndStoreGlobalValue("VALUE_RANGE_REQUESTED", true);
  const data = await updatePropertyProducts([
    "EstimatedValueRange",
    "iClarifyFinancialResidentialReport",
  ]);
  return data;
};

//----------------------------------------------------------
// load Rental Value for selected property type
//----------------------------------------------------------
export const loadRentalValue = async () => {
  setAndStoreGlobalValue("RENTAL_REQUESTED", true);
  const data = await updatePropertyProducts([
    "RentalValuation",
    "MarketValuationConfidence",
    "iClarifyFinancialResidentialReport",
  ]);
  return data;
};

//----------------------------------------------------------
// refresh current property images when using cached property info
//----------------------------------------------------------
export const loadImages = async () => {
  log(`loading property imagery from API`); //-------------- log
  const prodList = ["BuildingImagery"];
  const respData = await fetchProducts(null, prodList); // use current address
  if (respData) {
    const data = transformPropertyData(respData, prodList);
    setGlobal(data);
    return data;
  }
  return null;
};

//----------------------------------------------------------
// Update PDF Snapshot URL
//----------------------------------------------------------
export const loadSnapshotReport = async (callback = null) => {
  const f = isFunction(callback) ? callback : () => {}; // optional callback function
  await updatePropertyProducts(["iClarifySnapshotReport"]);
  f();
};
