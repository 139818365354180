import React, { Fragment, useEffect } from "react";
import { useGlobal } from "reactn";
import cx from "classnames";
import useLanguage from "../useLanguage";
import PropertyHeader from "./PropertyHeader";
import SaveButton from "./SaveButton";
import ReportButton from "./ReportButton";
import ProperyImages from "./PropertyImages";
import ResidentialFeatures from "./ResidentialFeatures";
import CondoFeatures from "./CondoFeatures";
import PropertyValuation from "./PropertyValuation";
import PropertyError from "./PropertyError";
import PropertyOverlordButton from "./PropertyOverlordButton";
import withPropertyFeatures from "../withPropertyFeatures";
import { isCondo, isResidential } from "../../lib/features-config";
import { getGlobalValue, setGlobalValue } from "../../lib/global-util";
import { removePropertyInfo, storeInitAddress } from "../../lib/local-data";
import { isEmpty } from "../../lib/util";
import { goHome } from "../../lib/session";
import getLogger from "../../lib/debug-logger";

import "../../styles/avm/property-view.scss";
import "../../styles/avm/property-features.scss";

const log = getLogger("PropertyView", 1);

const PropertyView = (props) => {
  const { featuresPresent, saveEnabled } = props;

  const [addressLine] = useGlobal("ADDRESS_LINE");
  const [propertyType] = useGlobal("PROPERTY_TYPE");
  const [overlord] = useGlobal("OVERLORD");

  // construction feats sources (exist for logged in as admin only)
  const [featSources] = useGlobal("FEATURES_SOURCES");

  const [resetEnabled] = useGlobal("RESET_ENABLED");

  // prefill data error, if present
  const [prefillError] = useGlobal("PREFILL_ERROR");

  const { t, language } = useLanguage();

  useEffect(() => {
    !!prefillError && log(t("PREFILL_ERROR")); //------------------ log
  }, [prefillError, t]);

  // trigger confirmation dialog preventing from leaving the page if edited features not saved;
  // don't use reactive values in this event handler
  useEffect(() => {
    const watcher = window.addEventListener("beforeunload", (ev) => {
      if (isEmpty(getGlobalValue("DIRTY_KEYS"))) return;
      ev.returnValue = "";
      ev.preventDefault();
      return "";
    });
    return () => {
      window.removeEventListener("beforeunload", watcher);
    };
  });

  // Reset logic: store current address and reload page
  const handleReset = () => {
    log("reset triggered"); //-------------- log
    storeInitAddress(addressLine);
    removePropertyInfo();
    setGlobalValue("DIRTY_KEYS", []); // this disables unload confirmation dialog
    goHome(); //--------------------- triggers page reload
  };

  return (
    <Fragment>
      <PropertyHeader {...props}>
        <div className="header-controls">
          <SaveButton disabled={!saveEnabled} />

          <button
            className={cx("big-button", "reset-btn", language)}
            onClick={handleReset}
            disabled={!resetEnabled}
          >
            {t("Reset")}
          </button>
          <ReportButton />
        </div>
      </PropertyHeader>

      <div className="property-view">
        <div className="property-view-images">
          <ProperyImages />
          {!!overlord && (
            <div className="pv-overlord">
              <PropertyOverlordButton />
            </div>
          )}
        </div>

        <div className={cx("property-view-main", { condensed: !!featSources })}>
          {!!prefillError && <PropertyError message={prefillError} />}

          {featuresPresent && (
            <Fragment>
              {isResidential(propertyType) && <ResidentialFeatures {...props} />}
              {isCondo(propertyType) && <CondoFeatures {...props} />}
              <PropertyValuation />
              {props.children}
            </Fragment>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default withPropertyFeatures(PropertyView);
