// Site navigation, language selector and sign out

import React from "react";
import cx from "classnames";
import TopLogo from "./TopLogo";
import TopNav from "./TopNav";
import NewSearchButton from "./NewSearchButton";

import "../../styles/avm/header.scss";

const Header = (props) => {
  const {propertyView = false} = props;


  return (
    <div className={cx("top-header", { extended: !!propertyView })}>
      <TopLogo />
      <TopNav />

      {!!propertyView && (
        <div className="second-line text-right">
          <NewSearchButton />
        </div>
      )}
    </div>
  );
};

export default Header;
