import React, { useCallback, useEffect, useState } from "react";
import { useGlobal } from "reactn";
import cx from "classnames";
import useLanguage from "../useLanguage";
import { isAdminRole } from "../../lib/user-roles";
import { dropSession, goToPage } from "../../lib/session";
import { getGlobalValue } from "../../lib/global-util";
import storage from "../../lib/localstorage";
import { isEmpty } from "../../lib/util";
import LangSwitch from "./LangSwitch";
import getLogger from "../../lib/debug-logger";

import "../../styles/avm/top-nav.scss";

const log = getLogger("TopNav", 1);

const currentPage = document.location.pathname.replace(/\.html$/, "").replace(/^\//, "");

const isSupportPage = ["support", "contact"].includes(currentPage);

const navigateTo = (name) => {
  goToPage(name, true); // remove property data, if exists in localstorage, and navigateTo
};

const itemClass = "site-nav-item";

const TopNav = () => {
  const [userRole] = useGlobal("USER_ROLE");
  const [overlord] = useGlobal("OVERLORD");
  const [username] = useGlobal("USERNAME");

  // Property address line - street address or legal address
  const [addrLine] = useGlobal("ADDRESS_LINE");
  const [addrLegal] = useGlobal("ADDRESS_LEGAL_LINE");

  const [contactPageName, setContactPageName] = useState("contact");
  const [homePageTitle, setHomePageTitle] = useState("");

  const { t } = useLanguage();

  useEffect(() => {
    const pn = !username ? "contact" : "support";
    setContactPageName(pn);
  }, [username, setContactPageName]);

  useEffect(() => {
    const pt = !username ? "Login" : "Property Search";
    setHomePageTitle(pt);
  }, [username, setHomePageTitle]);

  const showLangSwitch = useCallback(() => {
    return !addrLine && !addrLegal;
  }, [addrLine, addrLegal]);

  // on Sign Out require confirmation if user data changes not saved
  const signOut = (ev) => {
    if (
      getGlobalValue("PAGE_NAME") !== "main" ||
      isEmpty(getGlobalValue("DIRTY_KEYS")) ||
      window.confirm(t("Discard Changes?"))
    ) {
      log("Signing out"); //------------------ log
      dropSession();
    }
  };

  return (
    <div className="site-nav text-right">
      {isAdminRole(userRole) && currentPage !== "users" && (
        <span
          className={itemClass}
          onClick={() => {
            navigateTo("users");
          }}
        >
          {t("User Admin")}
        </span>
      )}
      {overlord && currentPage !== "property-review" && (
        <span
          className={itemClass}
          onClick={() => {
            storage.remove('review_address');
            storage.remove('reviev_filters');
            navigateTo("property-review");
          }}
        >
          {t("Overlord")}
        </span>
      )}
      {currentPage !== "" && (
        <span
          className={itemClass}
          onClick={() => {
            navigateTo();
          }}
        >
          {t(homePageTitle)}
        </span>
      )}
      {!isSupportPage && (
        <span
          className={itemClass}
          onClick={() => {
            navigateTo(contactPageName);
          }}
        >
          {t("Support")}
        </span>
      )}
      {showLangSwitch() && <LangSwitch />}
      {!!username && (
        <span className={cx(itemClass, "sign-out")} role="button" onClick={signOut}>
          {t("Sign Out")}
        </span>
      )}
    </div>
  );
};

export default TopNav;
