import React, { useState, useEffect } from "react";
import { getBrandingId } from "../../lib/global-util";
import withSession from "../withSession";

// CSS class is defined in main.scss
// inline style can be overridden
const defStyle = {
  position: "absolute",
  top: "8px",
  left: "40px",
};

const TopLogo = (props) => {
  const { loggedIn, style = null } = props;
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const vis = !loggedIn ? true : !getBrandingId();
    setVisible(vis);
  }, [loggedIn, setVisible]);

  return visible ? (
    <div
      className="opta-top-logo"
      style={Object.assign({}, defStyle, style)}
    ></div>
  ) : null;
};

export default withSession(TopLogo);
