// Rendering a list of previously visited addresses to choose from
import React, { useState, useEffect } from "react";
import cx from "classnames";
import useLanguage from "../useLanguage";
import { listAddresses, dropAddresses } from "../../lib/address-cache";
import { listSize } from "../../lib/util";
import { setGlobalValue } from "../../lib/global-util";
import getLogger from "../../lib/debug-logger";

import "../../styles/avm/address-pocket.scss";


const log = getLogger("AddressPocket", false);

const AddressPocket = () => {
  const [addresses, setAddresses] = useState(null);
  const [expanded, setExpanded] = useState(false);
  const { t } = useLanguage();

  useEffect(() => {
    if (!addresses) {
      const adds = listAddresses();
      log("saved addresses", adds);
      setAddresses(adds);
    }
  }, [addresses, setAddresses]);

  if (!listSize(addresses)) return null;

  const selectAddress = (addrStr) => {
    setGlobalValue("ADDRESS_LINE", addrStr);
    setExpanded(false);
  };

  const clearCache = () => {
    dropAddresses();
    setAddresses(null);
  };

  return (
    <div className="address-stack">
      <div className="address-stack--panel">
        <div className="address-stack--handle">
          <button
            className={cx(
              "btn-trigger",
              expanded ? "btn-collapse" : "btn-expand"
            )}
            onClick={() => setExpanded(!expanded)}
          ></button>
        </div>
        {expanded && (
          <ul className="address-stack--dropdown">
            {addresses.map((addr, idx) => {
              return (
                <li
                  key={idx}
                  className="button"
                  onClick={() => selectAddress(addr)}
                >
                  {addr}
                </li>
              );
            })}
            <li
              key={-1}
              className="button btn-reset"
              onClick={() => clearCache()}
            >
              {t("Clear")}
            </li>
          </ul>
        )}
      </div>
    </div>
  );
};

export default AddressPocket;
