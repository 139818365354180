const propertyStateKeys = [
  "ACTIVE_WILDFIRES_JSON",

  // current property address as string (| - separated street and city parts, or full search address string, if no address returned)
  "ADDRESS_LINE", // property current full street address, where available
  "ADDRESS_LINE_CITY", // second part of full street address
  "ADDRESS_LINE_STREET", // first part of full street address
  "ADDRESS_LEGAL_DESCRIPTION",
  "ADDRESS_LEGAL_LINE", // used in place of ADDRESS_LINE when searching by legal
  "ADDRESS_PID",
  "ADDRESS_REQ_OBJECT", // address object when searching by legal description
  "ADDRESS_RESP_OBJECT", // current property address object from OSS response

  "AVM_VALUE", // Number
  "AVM_RANGE_HIGH", // Number
  "AVM_RANGE_LOW", // Number
  "AVM_FAILED", // is set to true on proprty valuation errors

  "BRAND",

  "COMPARABLES",
  "COMPARABLES_HISTORY",
  "COMPARABLES_NUMBER",
  "COMPARABLES_RADIUS",
  "COMPARABLES_SELECTION",
  "COMPARABLES_OAKS", // comma-separated list as string, if exists

  "CONFIDENCE_RATE",

  "DATA_SCHEMA",

  "EARTHQUAKE_RISK",
  "EARTHQUAKE_ZONE",

  "ENV_REQUESTED", // enviromental products requested

  "FEATURES_CONF", // Object
  "FEATURES_CURRENT", // JSON string or null
  "FEATURES_INITIAL", // JSON string or null
  // original construction features w/o property stats added
  "FEATURES_PREFILL", // JSON string or null

  "FEATURES_MISSING", // missing construction features data keys

  "FEATURES_SOURCES",

  "FLOOD_SCORE",
  "FLOOD_MAP",
  "FLOOD_MAP_JSON",

  "GEOCODE", // stores {lat, lng} or null

  "HPI_HISTORICAL_JSON", // JSON-serialized HPIHistorical data

  // property images' URLs:
  // must be updated if expired
  "IMAGE_STREET",
  "IMAGE_MAP",

  "MARKET_METRICS_INITIAL", // JSON-serialized Market Metrics initial data
  "MARKET_METRICS_JSON", // JSON-serialized Market Metrics data

  "MUNICIPAL_CODE",
  "NEIGHBOURHOOD_NAME",

  "OAK",

  "PROPERTY_ASSESSMENTS",
  "PROPERTY_LISTINGS", // array
  "PROPERTY_PERMITS_JSON", // array
  "PROPERTY_SALES", // array

  "PROPERTY_TIME", // initial property data load timestamp
  "PROPERTY_TYPE", // residential or condominium

  "RENTAL_RANGE", // if populated, array of 2 numbers
  "RENTAL_TYPE", // rental unit type (use null as initial value)
  "RENTAL_VALUE", // number
  "RENTAL_REQUESTED",

  "REPORT_URL", // PDF Report URL
  "REPORT_USE_MS", // use Microservice calls for PDF Report (rather than OSS)

  "SEARCH_BY_LEGAL", // boolean, indicating that property search was done by legal entries

  "SHOW_RENTAL", // show items related to Rental Valuation?

  "SNAPSHOT_URL", // Property Snapshot PDF URL (used on Market Metrics page)

  "STATS_INITIAL",
  "STATS_UPDATED", // user-updated property stats
  "STATS_CURRENT", // changed but not saved yet

  "VALUATION_INITIAL", // initial valuation data as JSON
  "VALUATION_SNAPSHOT", // updated valuation data as JSON

  "VALUE_CHANGED", // Market Value was recalculated

  // flags indicating that valuation products have been requested
  "AVM_REQUESTED",
  "VALUE_RANGE_REQUESTED",

  "WILDFIRE_SCORE",
  "WILDFIRE_RISK",
];

//------------------------------------------------------------------------------
// The mapping below isn't imported anywhere yet, but is shown here for reference,
// because these global vars are actually used elsewhere
//------------------------------------------------------------------------------
export const userConfigVars = {
  // global settings for comparables
  COMPARABLES_RADIUS: 0,
  COMPARABLES_HISTORY: 0,
  COMPARABLES_NUMBER: 0,

  FEATURES_CONF: null, // property features' data schema

  OVERLORD: false, // Overlord action is allowed
  PRODUCTS_MAP: null, // common product names mapped to OSS and OSB signatures

  PROD_AVAIL_COMPARABLES: false, // MarketValueComparables product is available?
  PROD_AVAIL_CONFIDENCE: false, // MarketValuationConfidence product is available?
  PROD_AVAIL_LISTING: false,
  PROD_AVAIL_SALES: false,
  PROD_AVAIL_RENTAL_VAL: false, // RentalValuation product is available?
  PROD_AVAIL_VALUATION: false, // ResidentialMarketValuation product is available?
  PROD_AVAIL_VALUE_RANGE: false, // EstimatedValueRange product is available?
  PROD_AVAIL_STATS: false, // PropertyStats product is available?
  PROD_AVAIL_NEIGHBOURHOOD: false, // NeighbourhoodName
  PROD_AVAIL_METRICS: false, // MarketMetrics
  PROD_AVAIL_SNAPSHOT: false, // iClarifySnapshotReport
  PROD_AVAIL_HPI: false, // HPIHistorical
  PROD_AVAIL_PERMITS: false, // OptaPermitFinancial

  PROD_AVAIL_FLOOD: false,
  PROD_AVAIL_WILDFIRE: false,
  PROD_AVAIL_FUS: false,
  PROD_AVAIL_ACTIVE_WILDFIRES: false,

  //  this indicates that initial property data load has finished, either successfully or not
  PROPERTY_SOURCE: "", // 'net' or 'cache' or nothing

  READONLY_PREFILL: false, // user not allowed to edit features (AVMReadOnlyPrefill attribute is set to true)

  REVEAL_EVR: false, // reveal Estimated Value Range on initial data load
  REVEAL_AVM: false, // reveal Market Value and Confidence on initial data load
  REVIEW_ALLOWED: false, // flag for Submit For Review button

  SIGNING_OUT: false, // login throttling flag
};

// API errors related to products loaded on initial OSS data call,
// but used elsewhere
const productInitErrorKeys = [
  "ASSESSMENTS_ERROR",
  "LISTINGS_ERROR",
  "METRICS_ERROR",
  "HPI_ERROR",
  "RENTAL_VALUE_ERROR", // Error message returned by Rental product call
  "RENTALS_LIST_ERROR",
  "SALES_ERROR",
  "STATS_ERROR",
];

// global vars related to API errors

const apiErrorKeys = [
  "ERROR",
  "MESSAGE",
  "AVM_ERROR",
  "AVM_RANGE_ERROR",
  "CONFIDENCE_ERROR",
  "PREFILL_ERROR",
  "IMAGERY_ERROR",
  "REPORT_ERROR",
  "FLOODSCORE_ERROR",
  "WILDFIRE_ERROR",
  "FUS_ERROR",
  "ACTIVE_WILDFIRES_ERROR",
];

export const getPropertyStateKeys = () => [...propertyStateKeys, ...productInitErrorKeys];

export const getApiErrorKeys = () => [...apiErrorKeys];

export const EN = "en";
export const FR = "fr";
